import React from "react";
import "../App.css";
import StatueLiberty from "../imgs/liberty-statue.png";
import LogoMGC from "../imgs/mgc-logo.png";
import LogoTrivia from "../imgs/trivialogo.png";
import { useResolvedPath, useMatch, Link } from "react-router-dom";

export default function Footer(props) {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className={props.footerStyle}>
        <div className="projects-component">
          <h1 className="projects-title">Our Projects</h1>
          <div className="projects-block">
            <CustomLink
              onClick={() => scrollTop()}
              to="/"
              className="link-footer"
            >
              <img
                src={LogoMGC}
                alt="ManhattanGoldCoin Logo"
                className="mgc-logo"
              />
              <h4 title="projects-text">ManhattanGold Coin</h4>
            </CustomLink>
            <a
              onClick={() => scrollTop()}
              target="_blank"
              rel="noopener noreferrer"
              href="https://triviacoin.me/"
              className="link-footer"
            >
              <img
                src={LogoTrivia}
                alt="ManhattanGoldCoin Logo"
                className="trivia-logo"
              />
              <h4 title="projects-text">Trivia Coin</h4>
            </a>
          </div>
        </div>
        <img
          className="empire-building"
          src={StatueLiberty}
          alt="Empire State"
        />
        <div className="links-group">
          <CustomLink onClick={() => scrollTop()} to="/">
            <img className="footer-logo-desktop" src={LogoMGC} alt="Logo MGC" />
          </CustomLink>
          <div className="logo-component">
            <h5 className="links-title">LINKS</h5>
            <CustomLink
              onClick={() => scrollTop()}
              to="/"
              className="trivia-logo"
            >
              <img className="footer-logo" src={LogoMGC} alt="Logo MGC" />
            </CustomLink>
          </div>
          <div className="links-component">
            <ul className="links-list-block">
              <CustomLink
                onClick={() => scrollTop()}
                to="/"
                className="link-menu"
              >
                Home
              </CustomLink>
              <a
                href="https://pool.manhattangoldcoin.com/"
                className="link-menu"
                target="_blank"
                rel="noreferrer"
              >
                Pool
              </a>
              <a
                href="https://explorer.manhattangoldcoin.com/"
                className="link-menu"
                target="_blank"
                rel="noreferrer"
              >
                Explorer
              </a>
              <CustomLink
                onClick={() => scrollTop()}
                to="downloads"
                className="link-menu"
              >
                Downloads
              </CustomLink>
              <CustomLink
                onClick={() => scrollTop()}
                to="information"
                className="link-menu"
              >
                Information
              </CustomLink>
              <CustomLink
                onClick={() => scrollTop()}
                to="terms-services"
                className="link-menu"
              >
                Terms of Service
              </CustomLink>
            </ul>
          </div>
        </div>
        <span className="text-footer">&copy; 2018-2022 ManhattanGoldCoin</span>
      </div>
    </>
  );
}
function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
