import React, { useEffect, useState } from "react";
import "../App.css";
import MgcLogo from "../imgs/mgc-logo.png";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

export default function Navbar({
  setFooterStyle,
  setLinkEmail,
  setTxtEmail,
  setAnimateIcon,
  openNavbar,
  setOpenNavbar,
}) {
  const [navbarStyle, setNavbarStyle] = useState("navbar-menu");
  const [homeActive, setHomeActive] = useState("link-menu");
  const [downloadActive, setDownloadActive] = useState("link-menu");
  const [informationActive, setInformationActive] = useState("link-menu");
  const [termsActive, setTermsActive] = useState("link-menu");
  const [location] = useState(window.location.href);
  const loadedLink = () => {
    if (location === "https://manhattangoldcoin.com/") {
      setHomeActive("link-menu-active");
    } else if (location.includes("/explorer")) {
      setNavbarStyle("navbar-menu-color");
    } else if (location.includes("/downloads")) {
      setDownloadActive("link-menu-active");
      setNavbarStyle("navbar-menu-color");
    } else if (location.includes("/information")) {
      setInformationActive("link-menu-active");
      setNavbarStyle("navbar-menu-color");
    } else if (location.includes("/terms-services")) {
      setTermsActive("link-menu-active");
      setNavbarStyle("navbar-menu-color");
    }
  };

  useEffect(() => {
    loadedLink();
  });

  const handleActiveLink = (elemenClicked) => {
    if (elemenClicked === "home") {
      setHomeActive("link-menu-active");
      setTermsActive("link-menu");
      setDownloadActive("link-menu");
      setInformationActive("link-menu");
      setNavbarStyle("navbar-menu");
      window.scrollTo(0, 0);
    } else if (elemenClicked === "explorer") {
      setHomeActive("link-menu");
      setDownloadActive("link-menu");
      setTermsActive("link-menu");
      setInformationActive("link-menu");
      setNavbarStyle("navbar-menu-color");
      window.scrollTo(0, 0);
    } else if (elemenClicked === "downloads") {
      setDownloadActive("link-menu-active");
      setHomeActive("link-menu");
      setTermsActive("link-menu");
      setInformationActive("link-menu");
      setNavbarStyle("navbar-menu-color");
      window.scrollTo(0, 0);
    } else if (elemenClicked === "information") {
      setInformationActive("link-menu-active");
      setHomeActive("link-menu");
      setDownloadActive("link-menu");
      setTermsActive("link-menu");
      setNavbarStyle("navbar-menu-color");
      window.scrollTo(0, 0);
    } else if (elemenClicked === "terms-services") {
      setTermsActive("link-menu-active");
      setHomeActive("link-menu");
      setDownloadActive("link-menu");
      setInformationActive("link-menu");
      setNavbarStyle("navbar-menu-color");
      window.scrollTo(0, 0);
    }
    closeNavbarMob();
  };
  const openNavbarMobile = () => {
    if (openNavbar === "links-list") {
      setOpenNavbar("links-list-opened");
      setFooterStyle("footer-component-opened");
      setLinkEmail("email-link-opened");
      setTxtEmail("email-txt-opened");
      setAnimateIcon("c-scroll-icon-none");
    } else {
      setOpenNavbar("links-list");
      setFooterStyle("footer-component");
      setLinkEmail("email-link");
      setTxtEmail("email-txt");
      setAnimateIcon("c-scroll-icon");
    }
  };
  const closeNavbarMob = () => {
    setOpenNavbar("links-list");
    setFooterStyle("footer-component");
    setLinkEmail("email-link");
    setTxtEmail("email-txt-opened");
    setAnimateIcon("c-scroll-icon");
  };
  const listenScroll = () => {
    const element = document.getElementById("image-mob");

    const imagePosition = element.getBoundingClientRect().top;
    if (location !== "https://manhattangoldcoin.com/" || imagePosition < -50) {
      setNavbarStyle("navbar-menu-color");
    } else if (
      window.location.href === "https://manhattangoldcoin.com/" &&
      imagePosition >= -50
    ) {
      setNavbarStyle("navbar-menu");
    } else {
      return;
    }
  };
  window.addEventListener("scroll", listenScroll);
  return (
    <>
      <nav className={navbarStyle}>
        <div className="left-side">
          <Link
            onClick={() => handleActiveLink("home")}
            to="/"
            className="logo-menu"
          >
            <img className="logo" src={MgcLogo} alt="Logo MGC Coing" />
          </Link>
          <svg
            onClick={() => openNavbarMobile()}
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="white"
            className="menu-icon bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </div>

        <ul className={openNavbar}>
          <svg
            onClick={() => closeNavbarMob()}
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="#fff"
            id="close-icon"
            className="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
          <CustomLink
            onClick={() => handleActiveLink("home")}
            to="/"
            className={homeActive}
          >
            Home
          </CustomLink>
          <a
            href="https://pool.manhattangoldcoin.com/"
            className="link-menu"
            target="_blank"
            rel="noreferrer"
          >
            Pool
          </a>
          <a
            href="https://explorer.manhattangoldcoin.com/"
            className="link-menu"
            target="_blank"
            rel="noreferrer"
          >
            Explorer
          </a>
          <CustomLink
            onClick={() => handleActiveLink("downloads")}
            to="downloads"
            className={downloadActive}
          >
            Downloads
          </CustomLink>
          <CustomLink
            onClick={() => handleActiveLink("information")}
            to="information"
            className={informationActive}
          >
            Information
          </CustomLink>
          <CustomLink
            onClick={() => handleActiveLink("terms-services")}
            to="terms-services"
            className={termsActive}
          >
            Terms of Service
          </CustomLink>
        </ul>
      </nav>
    </>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
